<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('movement.programManagement') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
                  <tbody>
                    <tr>
                      <th>{{ $t('movement.program_no') }}</th>
                      <td>{{ $n(formData.program_name, { useGrouping: false }) }}</td>
                      <th>{{ $t('movement.allocationDate') }}</th>
                      <td>{{ formData.allocation_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('procurement.lc_no') }}</th>
                      <td>{{ $n(formData.lc_no) }}</td>
                      <th>{{ $t('movement.vesselsName') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.ship_name : formData.ship_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.sourceCountry') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.country_name : formData.country_name_bn }}</td>
                      <th>{{ $t('movement.fertilizerName') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.fertilizer_name : formData.fertilizer_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.port') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.port_name : formData.port_name_bn }}</td>
                       <th>{{ $t('movement.c_and_f_agent') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.agent_name : formData.agent_name_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.port_allocation') }}</th>
                      <td>{{ $n(formData.total_amount) }}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-table-simple bordered style="font-size:12px;color:#555555;line-height:2px">
              <b-thead head-variant="secondary">
                <b-tr>
                    <b-td class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                    <b-td class="text-center">{{ $t('movement.godownNameDistrict') }}</b-td>
                    <b-td class="text-right" v-for="(portInfoItem1, index) in transportAgentList" :key="index">{{ currentLocale === 'en' ? portInfoItem1.text_en : portInfoItem1.text_bn }}</b-td>
                    <b-th class="text-right">{{ $t('movement.totalMton') }}</b-th>
                </b-tr>
                <b-tr v-for="(portInfoItem1, index) in godownInfoList" :key="index">
                  <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                  <b-td class="text-center">{{ currentLocale === 'en' ? portInfoItem1.text_en : portInfoItem1.text_bn }}</b-td>
                  <b-td v-for="(portInfoItem2, index1) in transportAgentList" :key="index1" class="text-right">{{ $n(formData.program_details[index][index1].amount) }}</b-td>
                  <b-td class="text-right">{{ $n(formData.godown[index]) }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th colspan="2" class="text-right">{{ $t('movement.grandTotal') }}</b-th>
                  <b-th v-for="(portInfoItem1, index) in transportAgentList" :key="index" class="text-right">{{ $n(formData.agent[index]) }}</b-th>
                  <b-th bgcolor="" class="text-right">{{ $n(formData.grandtotal) }}</b-th>
                </b-tr>
              </b-thead>
          </b-table-simple>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { lcList, lcInfo, programManagementShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHeadFer.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.tempData = tmp
      if (tmp) {
          var temp = []
         this.godownInfoList.forEach((godown, godownIndex) => {
            var transportAgent = []
            this.transportAgentList.forEach((transport, transIndex) => {
                const programDetail = this.getProgramDetails(transport.value, godown.value)
                const item = {
                    trans_agent_name: transport.text_en,
                    godown_name: godown.text_en,
                    transport_agent_id: transport.value,
                    godown_infos_id: godown.value,
                    amount: programDetail ? programDetail.amount : null,
                    program_manage_details_id: programDetail ? programDetail.id : ''
                }
                transportAgent.push(item)
            })
            temp.push(transportAgent)
        })
        this.formData.program_details = temp
        this.formData.godown = []
        this.formData.agent = []
        this.getTotalAmounts(this.formData.program_details)
      }
    }
  },
  mounted () {
    core.index()
    this.getLcInfo()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        lc_no: '',
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        port_info: []
      },
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      lcList: [],
      lcInfo: {},
      portDetailsInfo: []
    }
  },
  computed: {
    portList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    getPortAllocationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getProgramDetails (transId, godownId) {
        const data = this.tempData.program_manage_details.find(doc => (doc.transport_agent_id === transId) && (doc.godown_infos_id === godownId))
        if (typeof data !== 'undefined') {
            return data
        } else {
            return null
        }
    },
    getTotalAmounts (data) {
        const n1 = this.godownInfoList.length
         for (let i = 0; i < n1; ++i) {
            this.formData.godown[i] = 0
         }

        data.forEach((godown, goindex) => {
            let sum = 0
            godown.forEach((item, agindex) => {
                if (item.amount != null) {
                    sum += parseFloat(item.amount)
                }
            })
            this.formData.godown[goindex] = sum
        })
        const n = this.transportAgentList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        data.forEach((godown, goindex) => {
            godown.forEach((item, agindex) => {
                if (item.amount != null) {
                    sum1[agindex] += parseFloat(item.amount)
                }
                this.formData.agent[agindex] = sum1[agindex]
            })
            let grand = 0
            sum1.forEach(item1 => {
                grand += item1
            })
            this.formData.grandtotal = grand
        })
    },
    async getPortDetailsInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, programManagementShow + this.id)
      let portDetailsInfo = []
      if (result.success) {
        portDetailsInfo = result.data.port_allocation_ports
        this.portList.filter(item => {
          const portDetailsItem = portDetailsInfo.find(portDetailsObj => portDetailsObj.port_id === item.value)
          const portInfoItem = {
            port_id: item.value,
            allocated_amount: typeof portDetailsItem !== 'undefined' ? portDetailsItem.allocated_amount : '',
            port_name: item.text_en,
            port_name_bn: item.text_bn
          }
          this.formData.port_info.push(portInfoItem)
        })
      } else {
        portDetailsInfo = []
      }
      return portDetailsInfo
    },
    async getLcList () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcList)
      if (result.success) {
        this.lcList = result.data
      } else {
        this.lcList = []
      }
    },
    async getLcInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcInfo + this.formData.lc_no)
      if (!result.success) {
        this.lcErrorMsg = result.message
        this.formData.ship_name = ''
        this.formData.ship_name_bn = ''
        this.formData.country_name = ''
        this.formData.country_name_bn = ''
        this.formData.fertilizer_id = ''
        this.formData.fertilizer_name = ''
        this.formData.fertilizer_name_bn = ''
        this.formData.bill_amount = ''
        this.formData.total_allocated_amount = ''
      } else {
        this.lcErrorMsg = ''
        const lcInfo = result.data
        this.lcInfo = result.data
        if (typeof lcInfo !== 'undefined') {
          const countryObj = this.importCountryList.find(item => item.value === lcInfo.country_id)
          const fartilizerObj = this.fertilizerNameList.find(item => item.value === lcInfo.fertilizer_id)
          this.formData.ship_name = lcInfo.ship_name
          this.formData.ship_name_bn = lcInfo.ship_name_bn
          this.formData.country_name = typeof countryObj !== 'undefined' ? countryObj.text_en : ''
          this.formData.country_name_bn = typeof countryObj !== 'undefined' ? countryObj.text_bn : ''
          this.formData.fertilizer_id = lcInfo.fertilizer_id
          this.formData.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
          this.formData.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
          this.formData.bill_amount = parseFloat(lcInfo.quantity)
          // this.formData.total_allocated_amount = parseFloat(lcInfo.quantity)
        } else {
          this.formData.ship_name = ''
          this.formData.ship_name_bn = ''
          this.formData.country_name = ''
          this.formData.country_name_bn = ''
          this.formData.fertilizer_id = ''
          this.formData.fertilizer_name = ''
          this.formData.fertilizer_name_bn = ''
          this.formData.bill_amount = ''
          this.formData.total_allocated_amount = ''
        }
        this.currentAvailableAmount = parseFloat(this.lcInfo.quantity) - parseFloat(this.formData.total_allocated_amount)
      }
    },
    pdfExport () {
        const reportTitle = this.$t('movement.programManagement')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.transportAgentList, this.godownInfoList)
    }
  }
}
</script>
