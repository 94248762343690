<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="allocation_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.allocationDate')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  class="fromDate"
                                  v-model="formData.allocation_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No"  vid="program_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="program_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="memo_no"
                                    v-model="formData.program_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="LC No"  vid="lc_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('procurement.lc_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="lc_no"
                                    v-model="formData.lc_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="vessels_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.vesselsName')}}
                              </template>
                              <b-form-input
                                  id="vessels_name"
                                  v-model="formData.ship_name"
                                  v-if="currentLocale === 'en'"
                                  readonly
                              ></b-form-input>
                              <b-form-input
                                  id="vessels_name"
                                  v-model="formData.ship_name_bn"
                                  v-else
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="country_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.sourceCountry')}}
                              </template>
                              <b-form-input
                                  id="country_name"
                                  v-model="formData.country_name"
                                  v-if="currentLocale === 'en'"
                                  readonly
                              ></b-form-input>
                              <b-form-input
                                  id="country_name"
                                  v-model="formData.country_name_bn"
                                  v-else
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="fertilizer_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fertilizer_name"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fertilizerName')}}
                                </template>
                                <b-form-input
                                    id="fertilizer_name"
                                    v-model="formData.fertilizer_name"
                                    v-if="currentLocale === 'en'"
                                    readonly
                                ></b-form-input>
                                <b-form-input
                                    id="fertilizer_name"
                                    v-model="formData.fertilizer_name_bn"
                                    v-else
                                    readonly
                                ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Port"  vid="port_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="port_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.port')}}  <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.port_id"
                                  :options="portList"
                                  id="port_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="C & F Agent"  vid="cnf_agent_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="cnf_agent_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.c_and_f_agent')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.cnf_agent_id"
                                    :options="agentList"
                                    id="cnf_agent_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    @change="cnfCheck"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  <div class="invalid-feedback d-block">
                                    {{ cnfMsg }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Port Allocation"  vid="fertilizer_name" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fertilizer_name"
                              >
                                <template v-slot:label>
                                  {{$t('movement.portAllocation')}}
                                </template>
                                <b-form-input
                                    id="fertilizer_name"
                                    v-model="formData.total_allocated_amount"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback d-block">
                                  {{ msg }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                      <span >
                        <b-thead head-variant="secondary">
                            <b-tr>
                                <b-td>{{ $t('globalTrans.sl_no') }}</b-td>
                                <b-td>{{ $t('movement.godownNameDistrict') }}</b-td>
                                <b-td v-for="(portInfoItem1, index) in transportAgentList" :key="index">{{ currentLocale === 'en' ? portInfoItem1.text_en : portInfoItem1.text_bn }}</b-td>
                                <b-td class="">{{ $t('movement.grandTotal') }}</b-td>
                            </b-tr>
                          <b-tr v-for="(portInfoItem1, index) in godownInfoList" :key="index">
                              <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                  <b-td>{{ currentLocale === 'en' ? portInfoItem1.text_en : portInfoItem1.text_bn }}</b-td>
                                  <b-td v-for="(portInfoItem2, index1) in transportAgentList" :key="index1">
                                    <b-form-input
                                    :id="'allocated_amount_godown_'+index+'_agent_'+index1"
                                    :ref="'allocated_amount_godown_'+index+'_agent_'+index1"
                                    type="number"
                                    step="any"
                                    v-model.number="formData.program_details[index][index1].amount"
                                    @keyup="getTotalAmount"
                                ></b-form-input></b-td>
                                <b-td><b-form-input
                                    :id="'allocated_'+(index+1)"
                                    :ref="'allocated_'+(index+1)"
                                    readonly
                                    type="number"
                                    v-model="formData.godown[index]"
                                ></b-form-input></b-td>
                          </b-tr>
                          <b-tr>
                                <b-th colspan="2" class="text-right">{{ $t('movement.totalMton') }}</b-th>
                                <b-th v-for="(portInfoItem1, index) in transportAgentList" :key="index"><b-form-input
                                    :id="'allocated_'+(index+1)"
                                    :ref="'allocated_'+(index+1)"
                                    readonly
                                    type="number"
                                    v-model="formData.agent[index]"
                                ></b-form-input></b-th>
                                <b-th><b-form-input
                                    :id="'allocated_'+(index+1)"
                                    :ref="'allocated_'+(index+1)"
                                    readonly
                                    type="number"
                                    v-model="formData.grandtotal"
                                ></b-form-input></b-th>
                            </b-tr>
                        </b-thead>
                          </span>
                      </b-table-simple>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import { programManagementStore, programManagementUpdate, lcInfo, portwiseAllocation, cnfAgentCheck } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.tempData = tmp
      if (tmp) {
          var temp = []
         this.godownInfoList.forEach((godown, godownIndex) => {
            var transportAgent = []
            this.transportAgentList.forEach((transport, transIndex) => {
                const programDetail = this.getProgramDetails(transport.value, godown.value)
                const item = {
                    trans_agent_name: transport.text_en,
                    godown_name: godown.text_en,
                    transport_agent_id: transport.value,
                    godown_infos_id: godown.value,
                    amount: programDetail ? programDetail.amount : null,
                    program_manage_details_id: programDetail ? programDetail.id : ''
                }
                transportAgent.push(item)
            })
            temp.push(transportAgent)
        })
        this.formData.program_details = temp
        this.formData.godown = []
        this.formData.agent = []
        this.getTotalAmounts(this.formData.program_details)
      }
    } else {
     temp = []
    this.godownInfoList.forEach((godown, godownIndex) => {
        var transportAgent = []
        this.transportAgentList.forEach((transport, transIndex) => {
            const item = {
                trans_agent_name: transport.text_en,
                godown_name: godown.text_en,
                transport_agent_id: transport.value,
                godown_infos_id: godown.value,
                amount: ''
            }
            transportAgent.push(item)
        })
        temp.push(transportAgent)
    })
    this.formData.program_details = temp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        allocation_date: '',
         program_details: [],
        program_name: '',
        lot_no: '',
        lc_no: '',
        port_id: 0,
        cnf_agent_id: 0,
        fiscal_year_id: 0,
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        amount: [],
        godown: [],
        agent: [],
        grandTotal: '',
        fertilizer_id: '',
        fertilizer_name: '',
        total_allocated_amount: '',
        current_available_amount: '',
        port_info: []
      },
      index: '',
      msg: '',
      cnfMsg: '',
      agentList: [],
      tempData: [],
      temp: [],
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      lcErrorMsg: ''
    }
  },
  computed: {
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
   watch: {
    'formData.lc_no': function (newVal, oldVal) {
      this.districtList = this.getLcInfos(newVal)
    },
    'formData.port_id': function (newVal, oldVal) {
        this.agentList = this.getAgent(newVal)
    },
    'formData.cnf_agent_id': function (newVal, oldVal) {
        this.temp = this.getAllocation(newVal)
    }
   },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${programManagementUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, programManagementStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.loading = false
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loading = false
      }
    },
    async getLcInfos (lcNo) {
      if (lcNo) {
        this.loading = true
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcInfo + lcNo)
        if (result.success) {
            this.formData.ship_name = result.data.ship_name
            this.formData.ship_name_bn = result.data.ship_name_bn
            this.formData.country_name = this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.find(item => item.value === result.data.country_id).text_en
            this.formData.country_name_bn = this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.find(item => item.value === result.data.country_id).text_bn
            this.formData.fertilizer_id = result.data.fertilizer_id
            this.formData.fertilizer_name_bn = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === result.data.fertilizer_id).text_bn
            this.formData.fertilizer_name = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === result.data.fertilizer_id).text_en
        }
        this.loading = false
      }
    },
    getAgent (Id) {
    const agent = this.$store.state.SeedsFertilizer.commonObj.cnfAgentList
      if (Id) {
        return agent.filter(doc => doc.port_id === Id)
      }
      return agent
    },
    async cnfCheck () {
      const search = {
          lc_no: this.formData.lc_no,
          port_id: this.formData.port_id,
          cnf_agent_id: this.formData.cnf_agent_id
      }
      const result = await RestApi.postData(seedFertilizerServiceBaseUrl, cnfAgentCheck, search)
      if (!result.success) {
        this.cnfMsg = 'This C&F Agent is already allocated under this L/C'
      } else {
        this.cnfMsg = ''
      }
    },
    async getAllocation (cfId) {
        const search = {
            lc_no: this.formData.lc_no,
            port_id: this.formData.port_id,
            cnf_agent_id: this.formData.cnf_agent_id
        }
        const result = await RestApi.postData(seedFertilizerServiceBaseUrl, portwiseAllocation, search)
        if (result.success) {
          if (result.data.quantity) {
            const qty = parseInt(result.data.quantity)
            this.formData.total_allocated_amount = qty
          } else {
            this.formData.total_allocated_amount = 0
          }
          this.msg = ''
        } else {
            this.formData.total_allocated_amount = 0
            this.msg = result.message
        }
    },
    getProgramDetails (transId, godownId) {
        const data = this.tempData.program_manage_details.find(doc => (doc.transport_agent_id === transId) && (doc.godown_infos_id === godownId))
        if (typeof data !== 'undefined') {
            return data
        } else {
            return null
        }
    },
    getTotalAmounts (data) {
        const n1 = this.godownInfoList.length
         for (let i = 0; i < n1; ++i) {
            this.formData.godown[i] = 0
         }

        data.forEach((godown, goindex) => {
            let sum = 0
            godown.forEach((item, agindex) => {
                if (item.amount != null) {
                    sum += parseFloat(item.amount)
                }
            })
            this.formData.godown[goindex] = sum
        })
        const n = this.transportAgentList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        data.forEach((godown, goindex) => {
            godown.forEach((item, agindex) => {
                if (item.amount != null) {
                    sum1[agindex] += parseFloat(item.amount)
                }
                this.formData.agent[agindex] = sum1[agindex]
            })
            let grand = 0
            sum1.forEach(item1 => {
                grand += item1
            })
            this.formData.grandtotal = grand
        })
    },
    getTotalAmount (event) {
        this.formData.program_details.forEach((godown, goindex) => {
            let sum = 0
            godown.forEach((item, agindex) => {
                const currentId = event.target.id
                if (currentId) {
                    if (!parseFloat(item.amount)) {
                        item.amount = 0
                    }
                }
                if (item.amount) {
                    sum += parseFloat(item.amount)
                }
            })
            this.formData.godown[goindex] = sum
        })
        const n = this.transportAgentList.length
        const a = new Array(n)
         for (let i = 0; i < n; ++i) {
            a[i] = 0
         }
         const sum1 = a
        this.formData.program_details.forEach((godown, goindex) => {
            godown.forEach((item, agindex) => {
                if (item.amount) {
                    sum1[agindex] += parseFloat(item.amount)
                }
                this.formData.agent[agindex] = sum1[agindex]
            })
            let grand = 0
            sum1.forEach(item1 => {
                grand += item1
            })
            this.formData.grandtotal = grand
        })
        if (this.formData.grandtotal > this.formData.total_allocated_amount) {
            const id = '#' + event.target.id
            const idValAmount = document.querySelector(id).value
            const remainingIdVal = Math.floor(idValAmount / 10)
            document.querySelector(id).value = remainingIdVal
            const newAllocatedAmount = remainingIdVal
            this.formData.grandtotal -= idValAmount
            this.formData.grandtotal += newAllocatedAmount
        }
    }
  }
}
</script>
