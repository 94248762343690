import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, transport, godown) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('movement.program_no'), style: 'td' },
                  { text: vm.$n(data.program_name, { useGrouping: false }), style: 'td' },
                  { text: vm.$t('globalTrans.date'), style: 'td' },
                  { text: dateFormat(data.allocation_date), style: 'td' }
                ],
                [
                  { text: vm.$t('procurement.lc_no'), style: 'td' },
                  { text: vm.$n(data.lc_no), style: 'td' },
                  { text: vm.$t('movement.vesselsName'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.ship_name : data.ship_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.sourceCountry'), style: 'td' },
                  { text: vm.$i18n.locale === 'en' ? data.country_name : data.country_name_bn, style: 'td' },
                  { text: vm.$t('movement.fertilizerName'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.fertilizer_name : data.fertilizer_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.port'), style: 'td' },
                  { text: vm.$i18n.locale === 'en' ? data.port_name : data.port_name_bn, style: 'td' },
                  { text: vm.$t('movement.c_and_f_agent'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.agent_name : data.agent_name_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.port_allocation'), style: 'td' },
                  { text: vm.$n(data.total_amount), style: 'td' },
                  { text: '', style: 'td' },
                  { text: '', style: 'td' }
                ]
              ]
            }
          }
        )
        pdfContent.push({ text: '', style: 'transport', alignment: 'center' })
        var tabledata = [
            { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('movement.godownNameDistrict'), style: 'td', alignment: 'center' }
          ]
        transport.map(doc => {
            const txt = { text: vm.$i18n.locale === 'en' ? doc.text_en : doc.text_bn, style: 'td', alignment: 'right' }
            tabledata.push(txt)
        })
        tabledata.push({ text: vm.$t('movement.totalMton'), style: 'th', alignment: 'right' })

        var tableRows = []
        tableRows.push(tabledata)
        godown.map((godown, godownIndex) => {
            var dist = [
                { text: vm.$n(godownIndex + 1), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'en' ? godown.text_en : godown.text_bn, style: 'td', alignment: 'center' }
              ]
            transport.map((transport, transportIndex) => {
                const txt = { text: vm.$n(data.program_details[godownIndex][transportIndex].amount), style: 'td', alignment: 'right' }
                dist.push(txt)
            })
            dist.push({ text: vm.$n(data.godown[godownIndex]), style: 'td', alignment: 'right' })
            tableRows.push(dist)
        })
        var tablefooter = [
            { text: vm.$t('movement.grandTotal'), colSpan: '2', style: 'th', alignment: 'right' },
            { }
          ]
        transport.map((doc, index) => {
            const txt = { text: vm.$n(data.agent[index]), style: 'th', alignment: 'right' }
            tablefooter.push(txt)
        })
        tablefooter.push({ text: vm.$n(data.grandtotal), style: 'th', alignment: 'right' })
        tableRows.push(tablefooter)
        pdfContent.push(
            {
              table: {
                  headerRows: 0,
                  style: 'header2',
                  widths: '*',
                  body: tableRows
              }
            }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fillColor: '',
              bold: true,
              fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td: {
                fontSize: (i18n === 'bn') ? 12 : 10,
              margin: [3, 3, 3, 3]
            },
            td1: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                bold: true,
                margin: [3, 3, 3, 3]
              },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 14,
              margin: [0, 10, 0, 20]
            },
            transport: {
              fontSize: 10,
              margin: [10, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 0]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            },
            krishi: {
              margin: [0, -5, 0, 10],
              alignment: 'center'
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('program-management')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
